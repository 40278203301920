'use client'
import styled from 'styled-components'
import { ApolloError } from '@apollo/client'
import { font18_25, createUnderline } from '@/styles'
import { useTranslator } from '@/hooks'

export const GraphQlErrors = ({ errors }) => {
  const { t } = useTranslator()
  if (!errors || errors?.length < 1) return null
  if(!Array.isArray(errors)) {
    console.log('errors', errors)
    return null
  }
  return errors.map((error, idx) => {
    if (!error || !error.message) return null
    if (error instanceof ApolloError) {
      // Handle GraphQL errors
      const graphqlErrors = error.graphQLErrors
      const networkError = error.networkError
      // console.log('GraphQL errors::', graphqlErrors)
      if (graphqlErrors) {
        return (
          <Li key={idx}>
            {graphqlErrors.map((item, idx) => (
              <p key={`gql-error-${idx}`}>{item.message}</p>
            ))}
          </Li>
        )
      }

      if (networkError) {
        // Handle network errors
        // console.log('Network error:', networkError)
      }
    } else {
      const accountDoesNotExist ='ACCOUNT_DOES_NOT_EXIST'
      const emailOrPasswordIncorrect = 'EMAIL_OR_PASSWORD_INCORRECT'
      const alreadyExists = 'ACCOUNT_EXISTS'
      const isSignUp = (error.extensions.code === alreadyExists)
      const shouldDisplayComponent = (error.extensions && ((error.extensions.code === accountDoesNotExist) || (error.extensions.code === emailOrPasswordIncorrect) ||  (error.extensions.code === alreadyExists)))

      const tooManyAttempts = 'TOO_MANY_ATTEMPTS'

      if(error.extensions && error.extensions.code === tooManyAttempts) {
        error.message = t('form.errors.too_many_attempts')
      }
      // Handle other non-GraphQL errors here
      // console.log('non-GraphQL error or Apollo response error', error)
      return (
        <Li key={idx}>
          {shouldDisplayComponent ?
            <>
              <p>{isSignUp ?  t('auth.signup.feedback.title') : t('auth.login.feedback.title')} {isSignUp ?'': t('auth.login.feedback.action')} <a href={`${isSignUp? 'login' : 'signup'}`}> {isSignUp ?  t('auth.signup.feedback.link') : t('auth.login.feedback.link')}</a></p>
            </>
            :
            <p>
              {typeof error === 'string' ? error : error.message}
            </p>
          }
        </Li>
      )
    }

  })
}

const Li = styled.li`
  margin: 0;
  padding: .75rem 1rem .75rem 0;
  width: 100%;

  &:first-child {
    margin-top: 1rem;
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  p {
    ${font18_25(false, 400)}
    color: ${({ theme }) => theme.colors.input_error};
    display:list-item;
    font-weight: 100;
    list-style:disc inside none;
    margin: 0;
    padding-left: .5rem;

    > a {
      ${createUnderline({ color: 'input_error', reverse: true, height:.75})}
    }
  }

  strong {
    margin-right: 1rem;
  }
`
