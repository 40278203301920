'use client'
import { type FC } from 'react'
import styled from 'styled-components'
import { mq, vw, font18_22 } from '@/styles'
import {  Link } from '@/components'
import { useTranslator } from '@/hooks'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { iconByName } from '@/utils'
import { Figure } from '../Figure'

export const SecondaryNav:FC = () => {
  const { lang } = useParams()
  const { t } = useTranslator()
  const pathname = usePathname()
  const { push } = useRouter()

  return (
    <NavStyled className={'secondary'} $pathName={pathname} >
      <ul>
        { pathname.includes('forgot-password') ? 
          <Figure className='back' media={iconByName('back')} onClick={()=>push(`/${lang}/login`)}></Figure>
          :
          <>
            <li className={`${pathname.includes('login') ? 'active' : ''}`}><Link href={`/${lang}/login`}>{t('layout.nav.login')}</Link></li>
            <li className={`${pathname.includes('signup') ? 'active' : ''}`}><Link href={`/${lang}/signup`}>{t('commons.actions.signup')}</Link></li>
          </>
        }
      </ul>
    </NavStyled>
  )
}

const NavStyled = styled.nav <{$pathName: string}>`
  align-items: flex-start;
  border-bottom: ${({ theme, $pathName }) => $pathName.includes('forgot-password') ? 'unset' :  `.5px solid ${theme.colors.neon_purple}`};
  display: flex;
  flex-direction: row;
  gap: ${vw(20, 'mobile')};
  justify-content: center;
  margin-bottom:  ${vw(30, 'mobile')};
  width:100%;

  ${mq.greaterThan('nexus7')} {
    gap: ${vw(20, 'tablet')};
    margin-bottom:  ${vw(30, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    gap: ${vw(20, 'desktop')};
    margin-bottom:  ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    gap: 20px;
    margin-bottom: 30px;
  }

  > ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    min-height: 20px;
    width: 80%;

    li {
      ${font18_22(false, 400)}
      align-items: center;
      align-self: stretch;
      color: ${({ theme }) => theme.colors.neon_purple};
      display: flex;
      flex-shrink: 0;
      gap: 10px;
      justify-content: center;
      opacity: .4;
      padding: 10px;
      text-align: center;
      width: 50%;
  
      &.active {
        border-bottom: 1.5px solid ${({ theme }) => theme.colors.neon_purple};
        opacity: 1;
      }
  
    }
  }
  
  > ul:has(.back) {
    justify-content: flex-start;
  }
  
  .back {
    height: ${vw(20, 'mobile')};
    width: ${vw(10, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(20, 'tablet')};
      width: ${vw(10, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(20, 'desktop')};
      width: ${vw(10, 'desktop')};

    }
    
    ${mq.greaterThan('desktop')} {
      height: 20px;
      width: 10px;
    }
  }
  
`

