'use client'
import {type FC, useMemo } from 'react'
import { font18_25, createUnderline } from '@/styles'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'

type Props = {
  errors: any,
}

const getFormErrors = (errors, passwordText, accepTermsComponent) =>{
  return Object.keys(errors).map((key, index) => {
    return(
      <Li key={index}> 
        {key === 'acceptTerms' ? 
          accepTermsComponent 
          : 
          <p>{key === 'password' ? passwordText : errors[key]?.message}</p>
        }
      </Li>
    )})
}
export const DisplayError: FC<Props> = ({errors}) => {
  const { t } = useTranslator()
  const accepTermsComponent = useMemo(() => <p>{(t('auth.signup.feedback_terms_of_use_1'))} <a href='/rules-and-privacy'>{(t('auth.signup.feedback_terms_of_use_2'))}</a></p> , [t])

  const FormErrorsItems = useMemo(() => getFormErrors(errors, t('form.errors.pattern_password') as string, accepTermsComponent), [accepTermsComponent, errors, t])
  return (
    <>
      {FormErrorsItems}
    </>
       
  )
}

const Li = styled.li`
  margin: 0;
  padding: .75rem 1rem .75rem 0;
  width: 100%;
  
  &:first-child {
    margin-top: 1rem;
  }
  
  &:last-child {
    margin-bottom: 1rem;
  }
  
  p {
    ${font18_25(false, 400)}
    color: ${({ theme }) => theme.colors.input_error};
    list-style:disc inside none;
    display:list-item; 
    margin: 0;
    font-weight: 100;
    padding-left: .5rem;
    > a {
      ${createUnderline({ color: 'input_error', reverse: true, height:.75})}
    }
  }

  strong {
    margin-right: 1rem;
  }
`
