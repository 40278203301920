'use client'
import { type ReactNode, type FC } from 'react'
import styled from 'styled-components'
import { mq, font50_48, vw, font15_20 } from '@/styles'

export const SectionAuth:FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Section>
      {children}
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 433px;
  padding: ${vw(70, 'mobile')} 0;
  width: unset;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(70, 'tablet')} 0;
  }

  ${mq.greaterThan('tablet')} {
    background-color: rgba(111, 78, 251, 0.1);
    border-radius: 20px;
    margin: 100px auto;
    max-width: 433px;
    padding: 50px 0;
    width:433px;
  }

  ${mq.greaterThan('desktop')} {
    margin: 100px auto;
    padding: 50px 0;
  }

  h1 {
    ${font50_48(true, 400)}
    color: var(--color-neon-purple);
    margin: ${vw(50, 'mobile')} ${vw(20, 'mobile')};
    text-align: center;

    ${mq.greaterThan('nexus7')} {
      margin: ${vw(50, 'tablet')} ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font50_48(false, 400)}
      text-align: center;
      margin: ${vw(50, 'desktop')} ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 50px 20px;
    }

  }

  form {
    input {
      background-color: transparent !important;
    }

    button {
      &[type='submit'] {
        ${font15_20(true, 600)}
        height: ${vw(50, 'mobile')};
        margin: ${vw(30, 'mobile')} auto 0;
        width: 90%;

        ${mq.greaterThan('nexus7')} {
          height: ${vw(50, 'tablet')};
          margin: ${vw(30, 'tablet')} auto 0;
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(50, 'desktop')};
          margin: ${vw(30, 'desktop')} auto 0;
          width: 90%;
        }

        ${mq.greaterThan('desktop')} {
          height: 50px;
          margin: 30px auto 0;
        }
      }
    }
  }
`
